import { LoaderIcon } from "@components/icons/LoaderIcon";
import WebinarCalendar from "@components/Webinar/WebinarCalendar";
import { checkStatus, parseJSON } from "@utils/fetch";
import dayjs, { Dayjs } from "dayjs";
import minMax from "dayjs/plugin/minMax";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";

dayjs.extend(utc);
dayjs.extend(minMax);

interface WebinarListOriginalType {
    uuid: string;
    id: number;
    host_id: string;
    topic: string;
    type: number;
    start_time: string;
    duration: number;
    timezone: string;
    created_at: string;
    join_url: string;
    is_simulive: boolean;
}
export interface WebinarListFEType extends Omit<WebinarListOriginalType, "start_time"> {
    start_time: Dayjs;
    time_slot: string;
}

interface WebinarProps {
    closeModal: () => void;
    handleTimeSlotPick: () => void;
    setSelectedWebinar: React.Dispatch<React.SetStateAction<WebinarListFEType | null | undefined>>;
    isStandalonePage: boolean;
}

const convertToLocalTime = (webinarList: WebinarListOriginalType[]): WebinarListFEType[] => {
    const currentDate = dayjs();
    const localTime = webinarList.map((webinar: WebinarListOriginalType) => {
        const localTime = dayjs(webinar.start_time).utc().local();
        return {
            ...webinar,
            start_time: localTime,
            time_slot: localTime.format("hh:mm A"),
        };
    });

    const futureWebinars = localTime.filter((webinar) => dayjs(webinar.start_time).isAfter(currentDate));

    return futureWebinars;
};

const Webinar: React.FC<WebinarProps> = (props) => {
    const { closeModal, handleTimeSlotPick, setSelectedWebinar, isStandalonePage } = props;
    const [webinarList, setWebinarList] = useState<WebinarListFEType[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        fetch("/api/v1/zoom-webinar/list")
            .then(checkStatus)
            .then(parseJSON)
            .then((list) => {
                setWebinarList(convertToLocalTime(list));
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, []);

    if (isLoading)
        return (
            <div className="w-full h-full flex items-center justify-center">
                <LoaderIcon />
            </div>
        );

    if (!webinarList)
        return (
            <div className="w-full h-full flex items-center justify-center">
                <p className="text-lg font-medium text-gray-500">No webinar available</p>
            </div>
        );

    return (
        <WebinarCalendar
            startDate={new Date().toDateString()}
            webinarList={webinarList}
            closeModal={closeModal}
            handleTimeSlotPick={handleTimeSlotPick}
            setSelectedWebinar={setSelectedWebinar}
            isStandalonePage={isStandalonePage}
        />
    );
};

export default Webinar;
