import { RefObject, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

interface ReCaptchaReturnProps {
    reCaptchaRef: RefObject<ReCAPTCHA>;
    triggerReCaptcha: () => void;
    generateToken: (token: string | null) => void;
}

const useRecaptcha = (onTokenGenerated: (token: string) => void): ReCaptchaReturnProps => {
    const reCaptchaRef = useRef<ReCAPTCHA>(null);

    const triggerReCaptcha = () => {
        if (reCaptchaRef.current) {
            reCaptchaRef.current.execute();
        }
    };

    const generateToken = (token: string | null) => {
        if (token) {
            onTokenGenerated(token);
            reCaptchaRef.current?.reset();
        }
    };

    return { reCaptchaRef, triggerReCaptcha, generateToken };
};

export default useRecaptcha;
