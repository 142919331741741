import { PropsWithChildren, useEffect, useState } from "react";
import ReactModal from "react-modal";
import Webinar from "./Webinar";
import BookDemoForm, { ThankyouModal } from "./BookDemoForm";
import { WebinarListFEType } from "./Webinar";
import { getUtmAndReferrer, SourceInformation } from "@utils/campaign-tracker";
interface BookDemoButtonProps {
    className?: string;
    isArabic?: boolean;
}

const desktopStyles: ReactModal.Styles = {
    content: {
        width: "750px",
        height: "620px",
        margin: "auto",
        padding: "32px",
        top: "10px",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        zIndex: 1000,
    },
};

const mobileStyles: ReactModal.Styles = {
    content: {
        width: "100%",
        height: "auto",
        paddingTop: "16px",
        paddingLeft: "4px",
        paddingRight: "4px",
        top: "110px",
        left: "0px",
        right: "0px",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        zIndex: 5,
    },
};

const BookDemoButton: React.FC<BookDemoButtonProps> = (props) => {
    const { className, isArabic } = props;
    return (
        <>
            <DesktopWebinarModal isStandalonePage={false}>
                <button
                    className={`${
                        className ? className : "py-2.5"
                    } main-button outline-none focus:outline-none start-trial-btn hover:no-underline hover:shadow-lg transition duration-300 ease-in-out inline-flex items-center justify-center md:justify-start gap-1 py-4`}
                    id="book-demo"
                >
                    {isArabic ? "احجز عرضًا توضيحيًا" : "Book a Demo"}
                </button>
            </DesktopWebinarModal>
        </>
    );
};

export default BookDemoButton;

interface DesktopWebinarModal extends PropsWithChildren {
    isStandalonePage: boolean;
}

export const DesktopWebinarModal: React.FC<DesktopWebinarModal> = (props) => {
    const { children, isStandalonePage } = props;
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [timeSlotPick, setTimeSlotPick] = useState<boolean>(false);
    const [selectedWebinar, setSelectedWebinar] = useState<WebinarListFEType | null>();
    const [isSuccessRegister, setIsSuccessRegister] = useState<boolean>(false);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [campaignDetails, setCampaignDetails] = useState<SourceInformation | undefined>({});

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setTimeSlotPick(false);
        setIsSuccessRegister(false);
    };

    const handleTimeSlotPick = () => {
        setTimeSlotPick((timeSlotPick) => !timeSlotPick);
    };

    const handleRegister = () => {
        setIsSuccessRegister(true);
    };

    const checkMobileView = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        checkMobileView();
        window.addEventListener("resize", checkMobileView);
        return () => {
            window.removeEventListener("resize", checkMobileView);
        };
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setCampaignDetails({ ...getUtmAndReferrer() });
        }, 0);
    }, []);

    const renderContent = () => {
        if (!timeSlotPick) {
            return <Webinar closeModal={closeModal} handleTimeSlotPick={handleTimeSlotPick} setSelectedWebinar={setSelectedWebinar} isStandalonePage={isStandalonePage} />;
        }
        if (!isSuccessRegister) {
            return (
                <BookDemoForm
                    selectedWebinar={selectedWebinar}
                    handleTimeSlotPick={handleTimeSlotPick}
                    closeModal={closeModal}
                    handleRegister={handleRegister}
                    setError={setError}
                    campaignDetails={campaignDetails}
                    isStandalonePage={isStandalonePage}
                />
            );
        }
        return error ? (
            <div className="text-center">Something went wrong. Please try again later</div>
        ) : (
            <ThankyouModal closeModal={closeModal} selectedWebinar={selectedWebinar} isStandalonePage={isStandalonePage} />
        );
    };

    return (
        <>
            <div onClick={openModal} className="inline">
                {children}
            </div>
            {isStandalonePage ? (
                renderContent()
            ) : (
                <ReactModal isOpen={modalIsOpen} style={isMobile ? mobileStyles : desktopStyles} onRequestClose={closeModal}>
                    {renderContent()}
                </ReactModal>
            )}
        </>
    );
};
