import { LoaderIcon } from "@components/icons/LoaderIcon";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { WebinarListFEType } from "./Webinar";
import { IoCloseOutline } from "react-icons/io5";

interface WebinarCalendarProps {
    startDate: string;
    webinarList: WebinarListFEType[];
    closeModal: () => void;
    handleTimeSlotPick: () => void;
    setSelectedWebinar: React.Dispatch<React.SetStateAction<WebinarListFEType | null | undefined>>;
    isStandalonePage: boolean;
}

const getMinStartTime = (webinars: WebinarListFEType[]) => {
    const currentDate = dayjs();

    const futureWebinars = webinars.filter((webinar) => dayjs(webinar.start_time).isSame(currentDate, "day") || dayjs(webinar.start_time).isAfter(currentDate));

    if (futureWebinars.length === 0) return null;

    const startTimes = futureWebinars.map((webinar) => dayjs(webinar.start_time));

    const minStartTime = dayjs.min(startTimes);

    return minStartTime ? new Date(minStartTime?.toString()) : new Date();
};

interface WebinarTimeSlotProps {
    webinarInfo: WebinarListFEType;
    isSelected: boolean;
    onTimeSlotClick: () => void;
    handleTimeSlotPick: () => void;
    setSelectedWebinar: React.Dispatch<React.SetStateAction<WebinarListFEType | null | undefined>>;
}

const WebinarTimeSlot: React.FC<WebinarTimeSlotProps> = (props) => {
    const { webinarInfo, isSelected, onTimeSlotClick, handleTimeSlotPick, setSelectedWebinar } = props;

    return (
        <div className="flex gap-2" style={{ width: "240px" }}>
            <button
                onClick={() => {
                    setSelectedWebinar(webinarInfo);
                    onTimeSlotClick();
                }}
                style={{ borderWidth: "1.5px" }}
                className={`flex-1 flex-shrink text-base font-bold px-5 py-2 whitespace-nowrap ${
                    isSelected ? "text-white bg-gray-600 rounded-md" : "text-blue-500 border border-solid border-blue-200 hover:border-blue-600 rounded-md"
                }`}
            >
                {webinarInfo.time_slot}
            </button>
            {isSelected && (
                <button className="bg-blue-600 text-white font-bold flex-1 flex-shrink px-3 py-2 rounded-md whitespace-nowrap" onClick={handleTimeSlotPick}>
                    Next
                </button>
            )}
        </div>
    );
};

const WebinarCalendar: React.FC<WebinarCalendarProps> = (props) => {
    const { startDate, webinarList, closeModal, handleTimeSlotPick, setSelectedWebinar, isStandalonePage } = props;

    const [selectedDate, setSelectedDate] = useState<any>(getMinStartTime(webinarList));
    const [isLoading, setLoading] = useState<boolean>(false);
    const [selectedTimeSlotIndex, setSelectedTimeSlotIndex] = useState<number | null>(null);

    const ref = useRef<HTMLDivElement>(null);

    const filterTimeSlots = React.useCallback(() => {
        const filteredList = webinarList.filter((value) => {
            return dayjs(value.start_time).isSame(dayjs(selectedDate), "day");
        });
        const sortedArray = filteredList.sort((a, b) => {
            return dayjs(a.start_time).isBefore(dayjs(b.start_time)) ? -1 : 1;
        });
        return sortedArray;
    }, [selectedDate, webinarList]);

    const availableTimeSlot = React.useMemo(() => {
        if (!selectedDate) return;
        return filterTimeSlots();
    }, [filterTimeSlots, selectedDate]);

    const onTimeSlotClick = (index: number | null) => {
        setSelectedTimeSlotIndex(index);
    };

    const timeSlot = React.useMemo(() => {
        if (isLoading) {
            return (
                <div className="flex items-center justify-center" style={{ width: "240px", height: "330px" }}>
                    <LoaderIcon />
                </div>
            );
        }
        if (selectedDate) {
            return (
                <div>
                    <div className={`mt-4 mb-5 text-center ${isStandalonePage ? "text-md" : "text-sm"}`}>{dayjs(selectedDate).format("dddd, MMMM D")}</div>
                    <div ref={ref} className="flex flex-col gap-3" style={{ width: "240px" }}>
                        {availableTimeSlot?.map((item, index) => {
                            return (
                                <WebinarTimeSlot
                                    key={index}
                                    webinarInfo={item}
                                    isSelected={index === selectedTimeSlotIndex}
                                    onTimeSlotClick={() => onTimeSlotClick(index)}
                                    handleTimeSlotPick={handleTimeSlotPick}
                                    setSelectedWebinar={setSelectedWebinar}
                                />
                            );
                        })}
                    </div>
                </div>
            );
        }
        return null;
    }, [isLoading, selectedDate, availableTimeSlot, selectedTimeSlotIndex, handleTimeSlotPick, setSelectedWebinar, isStandalonePage]);

    return (
        <section className="flex flex-col gap-8 w-full" style={{ minWidth: "360px !important" }}>
            <div className="flex justify-between items-center lg:gap-0 gap-8">
                <h2 className="lg:text-xl text-lg text-gray-700 font-semibold lg:pl-0 pl-2">Book a demo with our experts</h2>
                {!isStandalonePage ? (
                    <IoCloseOutline
                        size="24px"
                        width={20}
                        height={20}
                        className="text-gray-500 rounded-full hover:shadow-lg hover:text-white hover:bg-gray-400"
                        onClick={closeModal}
                    />
                ) : null}
            </div>
            <p className="text-gray-700 font-medium text-base mt-6 px-2 md:px-0">Select date and time</p>
            <div className="flex lg:flex-row flex-col lg:items-start items-center gap-10 mt-3">
                <div className={`h-fit ${isStandalonePage ? "demo" : ""}`}>
                    <Calendar
                        minDate={new Date(startDate)}
                        defaultValue={getMinStartTime(webinarList)}
                        minDetail="month"
                        onClickDay={(value) => {
                            const date = value.toDateString();
                            setSelectedDate(date);
                            setSelectedTimeSlotIndex(null);
                            setLoading(true);
                            setTimeout(() => {
                                setLoading(false);
                            }, 300);
                            setTimeout(() => {
                                ref.current?.scrollIntoView({
                                    behavior: "smooth",
                                });
                            }, 400);
                        }}
                        tileDisabled={({ date }) => {
                            const isEnabled = webinarList.some((item) => {
                                return dayjs(item.start_time).isSame(dayjs(date), "day");
                            });
                            return !isEnabled;
                        }}
                    />
                </div>
                {timeSlot}
            </div>
        </section>
    );
};
export default WebinarCalendar;
